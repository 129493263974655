import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useInterval } from 'react-use';
import { useSelector, useDispatch } from 'react-redux';
import { 
  LIVE_RELOAD_INTERVAL, 
  toggleLiveReload,
  selectLiveReload, 
} from "@/processes/Settings";
import IconLive from "@/shared/ui/Icons/IconLive";
import IconNotLive from "@/shared/ui/Icons/IconNotLive";
import Tooltip from "@/shared/ui/Tooltip";
import Button from "@/shared/ui/Button";
import cx from 'classnames';

interface LiveSwitcherProps {
  className?: string;
  callback?: () => void;
}

const INTERVAL_DELAY = 1000;

const LiveSwitcher = (props: LiveSwitcherProps) => {
  const {
    callback,
    className,
  } = props;

  const dispatch = useDispatch();
  const isLive = useSelector(selectLiveReload);
  const [countdown, setCountdown] = useState(LIVE_RELOAD_INTERVAL);

  const handleLiveToggle = () => {
    dispatch(toggleLiveReload(!isLive));
  }

  useInterval(
    () => {
      if (isLive && countdown > 0) {
        setCountdown(countdown - 1);
      } else {
        if( isLive && callback) { callback(); }
        setCountdown(LIVE_RELOAD_INTERVAL);
      }
    },
    isLive ? INTERVAL_DELAY : null
  );

  return (
    <Button 
      colorSchema='secondary'
      className={cx(
      'w-[32px] h-[30px]',
      className,
      )} 
      onClick={handleLiveToggle}
    >
      {isLive ? (
        <Tooltip ToggleComponent={<IconLive className="text-[#FF9500]" />}>
          <FormattedMessage id="live.switcher.hint" values={{ countdown }}/>
        </Tooltip>
      ) : (
        <Tooltip ToggleComponent={<IconNotLive className="text-white"/>}>
          <FormattedMessage id="not.live.switcher.hint" />
        </Tooltip>
      )}
    </Button>
  );
};

export default LiveSwitcher;
