import cx from 'classnames';
import Logo from './components/Logo';
import Login from './components/Login';
import { 
  AddonsMobile,
  ProductsMobile,
  SelectSubaccountMobile,
  SelectCoinMobile,
  HeaderProps,
} from '~/Header'

const HeaderMobile = (props: HeaderProps) => {
  const {
    className = '',
    withAddonsLeft = false,
    withSubAccounts = true,
    withAddonsRight = false,
  } = props;
  return (
    <div className={className}>
      <div className="flex items-center justify-between">
        <ProductsMobile className="w-2/5"/>
        
        <Logo className='flex-grow flex items-center justify-center'/>
        
        <div className="w-2/5 flex items-center justify-end">
          {withAddonsRight ? <AddonsMobile className='mr-3'/> : null}
          <Login/>
        </div>
      </div>

      <div className="mx-auto max-w-[400px] mt-4 flex items-center justify-center w-full">
        <SelectCoinMobile className={cx({
          'mr-2 w-1/2': withAddonsLeft,
          'w-full': !withAddonsLeft || !withSubAccounts,
        })}/>
        {withAddonsLeft && withSubAccounts && <SelectSubaccountMobile className='w-1/2'/>}
      </div>
    </div>
  );
}

export default HeaderMobile;
