import * as React from 'react';

const IconLive = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <circle cx="12" cy="12" r="2" fill="currentColor"></circle>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M16.66 15.779c-.217.268-.615.266-.86.022-.243-.244-.24-.638-.03-.912.615-.8.98-1.802.98-2.889a4.73 4.73 0 00-.98-2.89c-.21-.273-.213-.667.03-.91.245-.245.643-.247.86.021A5.975 5.975 0 0118 12a5.975 5.975 0 01-1.34 3.779zm1.953 1.95a.661.661 0 00.016.9.596.596 0 00.87-.013A9.962 9.962 0 0022 12a9.962 9.962 0 00-2.501-6.616.596.596 0 00-.87-.013.661.661 0 00-.016.9A8.716 8.716 0 0120.75 12a8.716 8.716 0 01-2.137 5.73zm-14.112.887a.596.596 0 00.87.013.661.661 0 00.016-.9A8.716 8.716 0 013.25 12c0-2.191.806-4.195 2.137-5.73a.661.661 0 00-.016-.9.596.596 0 00-.87.014A9.962 9.962 0 002 12c0 2.537.944 4.853 2.501 6.616zM8.199 15.8c.244-.244.24-.638.03-.912A4.73 4.73 0 017.25 12a4.73 4.73 0 01.98-2.89c.21-.273.213-.667-.03-.91-.245-.245-.643-.247-.86.021A5.975 5.975 0 006 12c0 1.432.502 2.747 1.34 3.779.217.268.615.266.86.022z"
      clipRule="evenodd"
    ></path>
  </svg>
);

export default IconLive;
