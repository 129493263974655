import {
  HeaderMobile,
  HeaderDesktop,
} from '~/Header'
import { useNavigation } from "@/processes/Router";

export type HeaderProps = {
  className?: string;
  withAddonsCenter?: boolean;
  withSubAccounts?: boolean;
  withAddonsLeft?: boolean;
  withAddonsRight?: boolean;
}

const Header = (props: HeaderProps) => {
  const { isDashboard  } = useNavigation();

  return (
    <>
      {/* reserve space */}
      <div className="h-[112px] lg:h-[78px]" aria-hidden="true"/>
      <header className="max-w-[1536px] w-full mx-auto py-4 px-4 mobile-ls:py-4 sm:py-6 fixed left-0 right-0 top-0 z-50 bg-[#000018]/[0.95]">
        <HeaderMobile className={`${isDashboard ? "lg:hidden" : "md:hidden"}`} {...props}/>
        <HeaderDesktop className={`${isDashboard ? "lg:flex" : "md:flex"} hidden items-center justify-between`} {...props}/>
      </header>
    </>
  )
}

export default Header;
