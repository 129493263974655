import api from '@/global/api';

export const extendedApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAccounts: build.query({
      query: () => ({
        url: `/v1/accounts`,
        method: 'GET',
      }),
      transformResponse: (response: any) => {
        return response?.accounts || [];
      },
    }),
  }),
});

export const {
  useGetAccountsQuery,
} = extendedApi;

export const {
  getAccounts,
} = extendedApi.endpoints;
