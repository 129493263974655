import { CURRENCY_SYMBOLS } from '@/shared/constants/currencies';
import _isNumber from 'lodash/isNumber';

export const getPower = (l:number, fixed:number = 2, fallback:number = 0) => {
  let value = l;
  let s = 1;
  let u = 'H/s';

  let KH = 1e3;
  let MH = 1e6;
  let GH = 1e9;
  let TH = 1e12;
  let PH = 1e15;
  let EH = 1e18;
  let ZH = 1e21;

  if (value >= KH) { s = KH; u = 'KH/s'; }
  if (value >= MH) { s = MH; u = 'MH/s'; }
  if (value >= GH) { s = GH; u = 'GH/s'; }
  if (value >= TH) { s = TH; u = 'TH/s'; }
  if (value >= PH) { s = PH; u = 'PH/s'; }
  if (value >= EH) { s = EH; u = 'EH/s'; }
  if (value >= ZH) { s = ZH; u = 'ZH/s'; }

  const valueOutput = value/s;

  return {
      value: !isNaN(valueOutput) ? valueOutput.toFixed(fixed) : fallback,
      divide: s,
      suffix: u,
  }
};

export const getFormattedPower = (val: number) => {
  const { value, suffix } = getPower(val);

  return `${value} ${suffix}`;
};

export const convertFormattedPowerToMax = (maxValue: number, value: number) => {
  const { divide, suffix } = getPower(maxValue);
  const valueInUnit = value / divide;

  return `${valueInUnit.toFixed(2)} ${suffix}`;
};

export const curToSymbol = (currency: string = 'USD') => {
  if (!currency) return '';

  return CURRENCY_SYMBOLS[currency.toUpperCase()] || '';
};

export const thousandSeparator = (number:number, delimeter = ',') => {
  if (!_isNumber(number)) {
    number = Number(number);
  }
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, delimeter);
}

/**
 * Format a number to 2 decimal places, but only if there are already decimals
 * Example:
 * '100' -> 100
 * 99.42423 -> 99.42
 */
export const getFloatOrDecimalNumber = (number: number) => {
  if (!number) return 0;
  return Math.round(Number(number) * 100) / 100;
}

export const getPercent = (value: number) => {
  return `${getFloatOrDecimalNumber(value)}%`;
}

export const toFixedNumber = (value = 0, fixed = 2) => {
  return Number(Number(value).toFixed(fixed));
};

export const trimTxHash = (hash: string = '') => {
  const limit = 9;
  const strLength = hash.length;
  const firstPart = hash.slice(0,limit)
  const lastPart = hash.slice(strLength - limit);
  return `${firstPart}...${lastPart}`;
}

export const trimAddress = (hash: string = '') => {
  const limit = 8;
  const strLength = hash.length;
  const firstPart = hash.slice(0,limit)
  const lastPart = hash.slice(strLength - limit);
  return `${firstPart}...${lastPart}`;
}

export const convertInputValueToNumber = (value: string) => {
  return Number(value.replace(/,/g, '.'));
}