import { useMinerAccount } from "@/processes/MinerAccounts";
import { useBreakpoint } from '@/shared/hooks/useBreakpoint'
import {
  LoggedIn,
  LoggedInMobile,
} from './components/LoggedIn';
import Guest from './components/Guest';

type LoginProps = {
  className?: string;
}

const Login = (props: LoginProps) => {
  const { className } = props;
  const { isMinMediaMd } = useBreakpoint()
  const { isAuth } = useMinerAccount();
  return isAuth 
    ? isMinMediaMd ? <LoggedIn className={className}/> : <LoggedInMobile className={className}/> 
    : <Guest className={className}/>;
}

export default Login;
