import { BASE_HOST, BUSINESS_PORTAL_URL } from '@/shared/constants/env';

export const NAVIGATION_PRODUCTS_LOGGED_IN = [
  {
    title: 'header.p.hiveon',
    link: 'https://the.hiveos.farm/',
  },
  {
    title: 'header.p.pool',
    link: BASE_HOST,
  },
  {
    title: 'header.p.business',
    link: BUSINESS_PORTAL_URL,
  },
];

export const NAVIGATION_PRODUCTS = [
  {
    title: 'header.p.hiveon',
    link: 'https://hiveon.com/os/',
  },
  {
    title: 'header.p.pool',
    link: BASE_HOST,
  },
  {
    title: 'header.p.firmware',
    link: 'https://hiveon.com/asic/',
  },
  {
    title: 'header.p.ahub',
    link: 'https://hiveon.com/asichub/',
  },
];

export const NAVIGATION_MORE = [
  // {
  //   title: 'header.p.firmware',
  //   link: 'https://hiveon.com/asic/',
  // },
  // {
  //   title: 'header.p.ahub',
  //   link: 'https://hiveon.com/asichub/',
  // },
  {
    title: 'header.p.blog',
    link: 'https://hiveon.com/blog/',
  },
  {
    title: 'header.p.forum',
    link: 'https://hiveon.com/forum/',
  },
  {
    title: 'header.p.kb',
    link: 'https://hiveon.com/knowledge-base/',
  },
  {
    title: 'header.p.referral',
    link: 'https://hiveon.com/referral/',
  },
];
