export const languages = {
  EN: 'en',
  ZH: 'zh',
};

export const LANGUAGES_LIST = [languages.EN, languages.ZH];

export const LANGUAGES_NAME = {
  [languages.EN]: 'English',
  [languages.ZH]: '中文',
}
