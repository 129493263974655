import React, { forwardRef } from 'react';
import cx from 'classnames';
import s from './List.module.scss';

/* -------------------------------------------------------------------------------------------------
 * List
 * -----------------------------------------------------------------------------------------------*/

type ListProps = {
  children: React.ReactNode;
  className?: string;
  [x:string]: any;
}

const List = (props: ListProps) => {
  const {
    children,
    className,
    ...restProps
  } = props;

  return (
    <ul className={cx(className, s.list)} {...restProps}>
      {children}
    </ul>
  );
}

/* -------------------------------------------------------------------------------------------------
 * ListItem
 * -----------------------------------------------------------------------------------------------*/

type ListItemProps = {
  children: React.ReactNode;
  isActive?: boolean;
  className?: string;
  theme?: 'primary' | 'secondary'
  tag?: 'a' | 'button';
  mb?: 'xs' | 'sm';
  [x:string]: any;
}

const ListItem = forwardRef((props: ListItemProps, ref: any) => {
  const {
    children,
    className,
    theme = 'primary',
    tag = 'a',
    isActive = false,
    mb = 'sm',
    ...restProps
  } = props;

  const TagComponent = tag;

  return (
    <li className={cx(s.item, s[mb])}>
      <TagComponent ref={ref} className={cx(s.itemLink, s[theme], className, {
        [s.active]: isActive,
      })} {...restProps}>
        {children}
      </TagComponent>
    </li>
  );
})

ListItem.displayName = 'ListItem';

/* -------------------------------------------------------------------------------------------------
 * ListSeparator
 * -----------------------------------------------------------------------------------------------*/

type ListSeparatorProps = {
  className?: string;
  [x:string]: any;
}

const ListSeparator = (props: ListSeparatorProps) => {
  const {
    className,
  } = props;

  return (
    <li className={className} aria-hidden="true"/>
  );
}


const Root = List;
const Item = ListItem;
const Separator = ListSeparator;

export {
  Root,
  Item,
  List,
  ListItem,
  Separator,
  ListSeparator,
};
