import cx from 'classnames'
import Logo from './components/Logo';
import Login from './components/Login';
import { 
  AddonsDesktop, 
  LanguageSwitcherDesktop,
  ProductsDesktop,
  SelectSubaccount,
  SelectCoin,
  CoinNav,
  HeaderProps,
} from '~/Header'

const HeaderDesktop = (props: HeaderProps) => {
  const { 
    className = '',
    withAddonsCenter = false,
    withSubAccounts = true,
    withAddonsLeft = false,
    withAddonsRight = false,
  } = props;
  return (
    <div className={cx(className)}>
      <div className={cx(
        "flex items-center justify-start",
        {
          'w-1/3': withAddonsCenter,
          'w-1/2': !withAddonsCenter,
        }
      )}>
        <Logo className='flex items-center'/>
        
        {withAddonsLeft ? (
          <div className="max-w-[400px] ml-5 flex items-center">
            <SelectCoin className="mr-2 min-w-[80px]"/>
            {withSubAccounts ? <SelectSubaccount/> : null}
          </div>
        ) : null}
      </div>

      {withAddonsCenter ? (
        <CoinNav/>
      ) : null}

      <div className={cx(
        "flex items-center justify-end",
        {
          'w-1/3': withAddonsCenter,
          'w-1/2': !withAddonsCenter,
        }
      )}>
        {withAddonsRight ? <AddonsDesktop className="flex items-center mr-7 pl-2"/> : null}
        <ProductsDesktop className="mr-7"/>
        <Login/>
        <LanguageSwitcherDesktop className="ml-4"/>
      </div>
    </div>
  );
}

export default HeaderDesktop;
