import { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import Button from '@/shared/ui/Button';
import Drawer from '@/shared/ui/Drawer';
import FieldWrapper from '@/shared/ui/FieldWrapper';
import { IconSettings } from '@/shared/ui/Icons';
import { SwipeHead } from '@/shared/ui/SwipeList';
import { 
  LiveSwitcher,
  TimeSwitcher,
 } from '~/Header'

type AddonsMobileProps = {
  className?: string;
}

const AddonsMobile = (props: AddonsMobileProps) => {
  const { 
    className = '' 
  } = props;
  const intl = useIntl();
  const [isOpen, setIsOpen] = useState(false);

  const handleShowDrawer = () => { setIsOpen(true); }
  const handleHideDrawer = () => { setIsOpen(false); }

  return (
    <div className={className}>
      <Button
        size="xs"
        aria-expanded={false}
        colorSchema='plain'
        className=''
        onClick={handleShowDrawer}
      >
        <IconSettings/>
      </Button>

      <Drawer
        open={isOpen}
        onInteractOutside={handleHideDrawer}
      >
        <SwipeHead
          title={intl.formatMessage({ id: 'dashboard'})}
          action={<FormattedMessage id="header.cancel"/>}
          onDismiss={handleHideDrawer}
        />
        <div className="w-full mb-3">
          <FieldWrapper
            full={true}
            label={intl.formatMessage({ id: 'live.switcher.period' })}
          >
            <TimeSwitcher />
          </FieldWrapper>
        </div>
        <div className="flex w-full items-center bg-white/[0.08] rounded-lg p-1">
          <LiveSwitcher className='mr-3'/>
          <span className='text-sm'><FormattedMessage id="not.live.switcher.hint"/></span>
        </div>
      </Drawer>
    </div>
  )
}

export default AddonsMobile;
