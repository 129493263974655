import { useMemo } from "react";
import { useNavigation } from "@/processes/Router";
import { useCoins } from "@/processes/PoolStats";
import { useWatcherLink } from '@/processes/WatcherLink';
import Select from '@/shared/ui/Select'
import CoinIcon from '@/shared/ui/CoinIcon';
import TextLoader from '@/shared/ui/TextLoader';

const SelectCoin = (props: any) => {
  const { filter, className = '', selectStyles, ...restProps } = props;
  const { coin, navigate, getPoolLandingLink } = useNavigation();
  const { isWatcherLink } = useWatcherLink();
  const { coins, isCoinsLoaded } = useCoins();

  const options = useMemo(() => {
    return coins.filter((coin: string) => {
      if (typeof filter === 'function') {
        return filter(coin);
      } else {
        return true;
      }
    }).map((coin: string) => ({
      value: coin,
      label: (
        <div className="flex items-center text-sm whitespace-nowrap pr-1">
          <CoinIcon coin={coin} className="h-3.5 w-3.5 mr-2 flex-shrink-0" />
          {coin}
        </div>
      ),
    }))
  }, [coins, filter])

  const selectedOption = useMemo(() => {
    return options.filter((option: any) => option.value === coin);
  }, [coin, options])
  const handleChangeCoin = (option: any) => {
    const link = getPoolLandingLink(option.value);
    navigate(link);
  };

  if (!isCoinsLoaded) {
    return <TextLoader width="80px" height="30px" className="rounded-lg mr-2" />
  }

  return (
    <Select
      isDisabled={isWatcherLink}
      className={className}
      styles={{
        ...selectStyles,
      }}
      value={selectedOption}
      options={options}
      onChange={handleChangeCoin}
      selectClassName="inline-flex min-w-[90px]"
      menuFull
      {...restProps}
    />
  )
};

export default SelectCoin;
