import { FormattedMessage } from 'react-intl'
import Button from '@/shared/ui/Button';
import { loginWithRedirect } from '@/global/utils/apiHelpers';
import { BASE_HOST } from '@/shared/constants/env';
import { useNavigation } from '@/processes/Router';

type GuestProps = {
  className?: string;
}

const Guest = (props: GuestProps) => {
  const { className } = props;
  const { coinLower, isAccounting, landingUrl } = useNavigation();
  const redirectAfterLogin = isAccounting ? `${BASE_HOST}/${coinLower}/dashboard` : `${BASE_HOST}/${landingUrl}`;
  
  return (
    <div className={className}>
      <Button component='a' target="_blank" href={loginWithRedirect(redirectAfterLogin)} colorSchema="primary">
        <FormattedMessage id="header.sign.in"/>
      </Button>
    </div>
  );
}

export default Guest;
