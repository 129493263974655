import React from 'react';
import cx from "classnames";
import s from './MainLayout.module.scss';
import { useNavigation } from '@/processes/Router';

type MainLayoutProps = {
  className?: string
  children: React.ReactNode
}

const MainLayout = (props: MainLayoutProps) => {
  const { className, children } = props;

  const { coin } = useNavigation();

  return (
    <main className={cx('pt-4 md:pt-10 pb-10 flex-grow', className, {"inner" : coin !== 'KAS'})}>
      {children}
    </main>
  );
}

export default MainLayout;
