import { useRouter } from 'next/router';
import _toLower from "lodash/toLower";
import _get from "lodash/get";
import _omitBy from "lodash/omitBy";
import _isNil from "lodash/isNil";

import { TOKEN_KEY } from '@/shared/constants/token'; 

export const useWatcherLink = (props = {}) => {
  const { query } = useRouter();
  
  const token = _get(query, TOKEN_KEY, null);
  const isWatcherLink = !!token;

  return {
    token,
    isWatcherLink,
  }
};

export default useWatcherLink;