import cx from 'classnames';
import { FormattedMessage } from 'react-intl'
import logo from '@/shared/assets/logo.png';
import Link from 'next/link';

type LogoProps = {
  className?: string;
}

const Logo = (props: LogoProps) => {
  const { className } = props;
  return (
    <div className={cx('whitespace-nowrap', className)}>
      <Link className='inline-flex items-center' href="/">
        <img src={logo.src} alt="Hiveon" className='inline-block w-[23px] items-center mr-2' />
        <span className='hidden sm:inline text-[#EEF0F2]/[0.5] font-medium'>Hiveon</span>
      </Link>
      <span className='hidden w-[2px] h-[18px] bg-[#D9D9D9]/[0.2] mx-4 sm:inline-flex'/>
      <FormattedMessage id="head.product"/>
    </div>
  );
}

export default Logo;
