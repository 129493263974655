import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import cx from 'classnames';
import _get from 'lodash/get';
import _find from 'lodash/find';
import Popover from '@/shared/ui/Popover';
import * as List from '@/shared/ui/List';
import Button from '@/shared/ui/Button';
import { LANGUAGES_LIST } from '@/shared/constants/language';
import { useNavigation } from '@/processes/Router';
import { IconGlobe } from '@/shared/ui/Icons';
import s from './LanguageSwitcherDesktop.module.scss';

const LanguageSwitcherDesktop = (props: any) => {
  const { locale, switchLocale } = useNavigation();
  const [isOpen, setIsOpen] = useState(false);
  const intl = useIntl();

  const {
    className,
  } = props;

  const handleLanguageChange = (lang: string) => {
    setIsOpen(false);
    switchLocale(lang);
  };

  return (
    <>
      <Popover
        open={isOpen}
        onOpenChange={setIsOpen}
        triggerComponent={
          <Button fw="normal" prefixIcon={
            <IconGlobe className="w-4"/>
          } colorSchema="plain" size="none" className={cx(s.toggler, className)}>
            {locale}
          </Button>
        }
        className='w-fit mt-2 z-100'
      >
        <div>
          <span
            className='sr-only'
            tabIndex={0} 
            aria-label={
              `${intl.formatMessage({ id: "header.language" })}: ${locale}`
            }
          />
          <List.Root className='backdrop-blur-[40px]'>
            {LANGUAGES_LIST.map((lang) => {
              return (
                <List.Item 
                  onClick={() => handleLanguageChange(lang)}
                  tag="button"
                  theme='secondary' 
                  className='uppercase font-semibold text-sm' 
                  key={lang}
                >
                  {lang}
                </List.Item>
              )
            })}
          </List.Root>
        </div>
      </Popover>
    </>
  );
};

export default LanguageSwitcherDesktop;
