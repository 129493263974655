import * as React from 'react';

const IconNotLive = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeWidth="1.25"
      d="M20 20L4 4"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6.83 8.952l.922.921A4.73 4.73 0 007.25 12a4.73 4.73 0 00.98 2.89c.21.273.213.667-.03.91-.245.245-.643.247-.86-.021A5.975 5.975 0 016 12c0-1.113.303-2.155.83-3.048zM3.949 6.069l.895.896A8.71 8.71 0 003.25 12c0 2.191.806 4.195 2.137 5.73a.661.661 0 01-.016.9.596.596 0 01-.87-.014A9.962 9.962 0 012 12c0-2.22.724-4.272 1.948-5.93zm5.154 5.154l3.675 3.675a3.004 3.004 0 01-3.675-3.675zm5.796 1.554l-3.675-3.675a3 3 0 013.676 3.675zm2.271 2.271l-.92-.921A4.73 4.73 0 0016.75 12a4.73 4.73 0 00-.98-2.89c-.21-.273-.213-.667.03-.91.245-.245.643-.247.86.021A5.975 5.975 0 0118 12a5.973 5.973 0 01-.83 3.048zm2.883 2.883l-.895-.896A8.71 8.71 0 0020.75 12a8.716 8.716 0 00-2.137-5.73.661.661 0 01.016-.9.596.596 0 01.87.014A9.962 9.962 0 0122 12c0 2.22-.724 4.272-1.948 5.93z"
      clipRule="evenodd"
    ></path>
  </svg>
);

export default IconNotLive;
