import React, { useState, useRef, useEffect, useMemo } from 'react';
import cx from 'classnames';
import { useDispatch } from 'react-redux';
import { FormattedMessage, useIntl } from "react-intl";
import _toLower from 'lodash/toLower'
import s from './SelectSubaccount.module.scss';
import Drawer from '@/shared/ui/Drawer';
import Button from '@/shared/ui/Button';
import * as List from '@/shared/ui/List';
import { SwipeHead } from '@/shared/ui/SwipeList';
import Input from "@/shared/ui/Input";
import { trimAddress } from "@/shared/utils/formatters";
import CoinIcon from "@/shared/ui/CoinIcon";
import { IconSearch, IconSupport, IconSortUnfold, IconArrowTo } from "@/shared/ui/Icons";
import { 
  setMinerAccount,
  addToRecentSearches,
  useMinerAccount,
} from "@/processes/MinerAccounts";
import { useNavigation } from '@/processes/Router';
import { useGetAccountsQuery } from "~/Header/api";

type SelectSubaccountMobileProps = {
  className?: string;
}

const SelectSubaccountMobile = (props: SelectSubaccountMobileProps) => {
  const {
    className = '',
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const intl = useIntl();
  const dispatch = useDispatch();
  const { coin, isSupportSubAccount, isGRAM, getPoolDashboardLink, navigate } = useNavigation();

  const { 
    account,
    miner,
    isSupport, 
    isAccounting,
    recentSearches, 
    isAuth,
    findValidAddress,
  } = useMinerAccount();
  const { data: accounts = [], isSuccess } = useGetAccountsQuery(null, { skip: !(isAuth && isSupportSubAccount) });
  
  const handleShowDrawer = () => { setIsOpen(true); }
  const handleHideDrawer = () => { setIsOpen(false); }

  let selectedAccount;

  if (isAccounting) {
    if (isGRAM) {
      selectedAccount = !!miner.length ? trimAddress(miner) : account;
    } else {
      selectedAccount = account;
    }
  } else {
    selectedAccount = trimAddress(miner);
  }

  const searchRef = useRef(null);  
  const [searchQuery, setSearchQuery] = useState('');
  
  const isSupportAccount = isSupport && account && !accounts.find((a: any) => a.name === account);

  const minerAccountOptions = useMemo(() => {
    const options = accounts.filter((a: any) => !a.deactivated).map((a: any) => {
      return {
        value: a.name,
        label: a.name,
      };
    });
    // if support opened account which is not existing, add this accoun to the end of the list
    if (isSupportAccount) {
      options.push({
        value: account,
        label: `${account} - [support]`,
      });
    }
    return options;
  }, [accounts, isSupportAccount, account]); 

  const handleSearchChange = (e: any) => {
    setSearchQuery(e.target.value);
  };

  const filterAccounts = ((item: any) => {
    const { value } = item;
    return _toLower(value).includes(_toLower(searchQuery));
  });
  const minerAccountOptionsFiltered = minerAccountOptions.filter(filterAccounts);

  const filterMinerAddresses = ((item: any) => {
    const { address } = item;
    return _toLower(address).includes(_toLower(searchQuery));
  });
  const recentSearchesFiltered = recentSearches.filter(filterMinerAddresses);

  const handleSubAccountSelect = (value: string) => {
    handleHideDrawer();
    dispatch(setMinerAccount({ selectedAccount: value }));
    setSearchQuery('');
    const dashboardUrl = getPoolDashboardLink({ account: value, coin, support: isSupport });
    navigate(dashboardUrl, undefined, { shallow: true });
  };
  const { isValid: isSearchValid, coin: searchCoin } = findValidAddress(searchQuery);

  const handleMinerAddressSelect = ({ coin, address, isSearch = false }: any) => {
    handleHideDrawer();
    setSearchQuery('');
    // save to recent searches
    if (isSearch) {
      dispatch(addToRecentSearches({ coin, address }));
    }
    // navigate to dashboard
    const dashboardUrl = getPoolDashboardLink({ miner: address, coin });
    navigate(dashboardUrl);
  };

  const handleFormSubmit = (e: any) => {
    e.preventDefault();
    if (isSearchValid) {
      handleMinerAddressSelect({ address: searchQuery, coin: searchCoin, isSearch: true })
    }
  }

  // TODO: add loader
  // add <TextLoader width="56px" height="26px" className="rounded-lg" />
  
  return (
    <>
      <Button 
        onClick={handleShowDrawer}
        className={className}
        fw="normal"
        colorSchema="secondary" 
        justify="between"
      >
        {isSupportAccount ? (
          <IconSupport className="h-3 w-3 mr-1.5"/>
        ) : null}
        {selectedAccount 
          ? selectedAccount
          : intl.formatMessage({ id: 'header.subaccount.placeholder' })
        } 
        <IconSortUnfold className="w-3"/>
      </Button>

      <Drawer
        open={isOpen}
        onInteractOutside={handleHideDrawer}
      >
        <SwipeHead
          title={intl.formatMessage({ id: 'header.subaccount.choose'})}
          action={<FormattedMessage id="header.cancel"/>}
          onDismiss={handleHideDrawer}
        />

        <div className="mobile-ls:max-h-[55vh] max-h-[75vh] overflow-y-auto">
          <form onSubmit={handleFormSubmit}>
            <Input
              className="mb-4"
              ref={searchRef}
              prefix={<IconSearch className="text-white" />}
              placeholder={intl.formatMessage({id: 'header.subaccount.select.input.placeholder'})}
              value={searchQuery}
              onChange={handleSearchChange}
              full
            />
          </form>

          {recentSearchesFiltered.length > 0 ? (
            <>
              <div className={cx(s.listTitle)}><FormattedMessage id="header.subaccount.select.recenlty.title" /></div>
              <List.Root className='mb-4'>
                {recentSearchesFiltered.map(({coin, address}: any) => (
                  <List.Item 
                    mb="xs" 
                    theme='secondary' 
                    className='' 
                    tag="button"
                    key={address}
                    onClick={() => handleMinerAddressSelect({ coin, address })}
                  >
                    <span className='flex items-center'>
                      <CoinIcon coin={coin} className='h-3 w-3 mr-1'/>
                      {trimAddress(address)}
                    </span>
                    <IconArrowTo className="w-4"/>
                  </List.Item>
                ))}
              </List.Root>
            </>
          ) : (
            isSearchValid ? (
              <List.Root className='mb-4'>
                <div className={s.listTitle}><FormattedMessage id="header.subaccount.select.search.result" /></div>
                <List.Item 
                  mb="xs" 
                  theme='secondary' 
                  className='' 
                  tag="button"
                  onClick={() => handleMinerAddressSelect({ coin: searchCoin, address: searchQuery, isSearch: true })}
                >
                  <span className='flex items-center'>
                    <CoinIcon coin={searchCoin} className='h-3 w-3 mr-1'/>
                    {trimAddress(searchQuery)}
                  </span>
                  <IconArrowTo className="w-4"/>
                </List.Item>
              </List.Root>
            ) : null
          )}

          {/* accounts */}
          {isAccounting && minerAccountOptionsFiltered.length > 0 ? (<>
            <div className={s.listTitle}><FormattedMessage id="header.subaccount.select.title" /></div>
            
            <List.Root className='overflow-y-auto max-h-[225px]'>
              {minerAccountOptionsFiltered.map((option: any) => {
                const { value, label } = option;
                return (
                <List.Item 
                  mb="xs" 
                  theme='secondary' 
                  className='' 
                  tag="button"
                  key={value}
                  onClick={() => handleSubAccountSelect(value)}
                >
                  {label}
                </List.Item >
                );
              })}
            </List.Root>
          </>) : null}
        </div>
      </Drawer>
    </>
  );
}

export default SelectSubaccountMobile;
