import Link from "next/link";
import cx from "classnames";
import { useNavigation } from "@/processes/Router";
import { useCoins } from "@/processes/PoolStats";
import CoinIcon from '@/shared/ui/CoinIcon';
import TextLoader from '@/shared/ui/TextLoader';
import s from './CoinNav.module.scss';

const CoinNav = (props: any) => {
  const { filter, className = '', selectStyles, ...restProps } = props;
  const { coin, getPoolLandingLink } = useNavigation();
  const { coins, isCoinsLoaded } = useCoins();

  if (!isCoinsLoaded) {
    return <TextLoader width="50px" height="20px" className="rounded-lg mr-2" />
  }

  return (
    <nav className="flex justify-center items-center">
      {coins.map((coinItem: string) => {
        const isActive = coin === coinItem;
        const link = getPoolLandingLink(coinItem);
        return (
          <Link
            className={cx(
              s.item,
              {
                [s.active]: isActive
              }
            )}
            key={coinItem} 
            href={link}
          >
            <CoinIcon coin={coinItem} className="h-3.5 w-3.5 mr-2" />
            {coinItem}
          </Link>
        )
      })}
    </nav>
  )
};

export default CoinNav;
