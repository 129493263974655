import { useSelector } from 'react-redux';
import { selectUsername } from '@/processes/Auth';
import Avatar from '@/shared/ui/Avatar';
import Popover from '@/shared/ui/Popover';
import Box from '@/shared/ui/Box';
import LoggedInContent from './LoggedInContent';

type LoggedInProps = {
  className?: string;
}

const LoggedIn = (props: LoggedInProps) => {
  const { className } = props;
  const username = useSelector(selectUsername);

  return (
    <div className={className}>
      <Popover
        triggerComponent={
          <Avatar type="button" name={username}/>
        }
        className='w-[260px] z-100 sm:w-[332px]'
      >
        <Box>
          <LoggedInContent/>
        </Box>
      </Popover>
    </div>
  );
}

export default LoggedIn;
