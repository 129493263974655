import { 
  LiveSwitcher,
  TimeSwitcher,
 } from '~/Header'

type AddonsDesktopProps = {
  className?: string;
}

const AddonsDesktop = (props: AddonsDesktopProps) => {
  const {
    className = '',
  } = props;
  return (
    <div className={className}>
      <LiveSwitcher className='mr-4'/>
      <TimeSwitcher />
    </div>
  )
}

export default AddonsDesktop;
