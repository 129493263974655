import { useState } from "react";
import cx from "classnames";
import { FormattedMessage, useIntl } from 'react-intl'
import { useNavigation } from "@/processes/Router";
import { useCoins } from "@/processes/PoolStats";
import Drawer from '@/shared/ui/Drawer';
import * as List from '@/shared/ui/List';
import { SwipeHead } from '@/shared/ui/SwipeList';
import TextLoader from '@/shared/ui/TextLoader';
import Button from '@/shared/ui/Button';
import { IconSortUnfold } from '@/shared/ui/Icons';
import CoinIcon from '@/shared/ui/CoinIcon';

const SelectCoinMobile = (props: any) => {
  const { filter, className = '', selectStyles, ...restProps } = props;
  const intl = useIntl();
  const { coin, navigate, getPoolLandingLink } = useNavigation();
  const { coins, isCoinsLoaded } = useCoins();
  const [isOpen, setIsOpen] = useState(false);
  const handleShowDrawer = () => { setIsOpen(true); }
  const handleHideDrawer = () => { setIsOpen(false); }

  const handleChangeCoin = (selectedCoin: string) => {
    const link = getPoolLandingLink(selectedCoin);
    navigate(link);
    handleHideDrawer();
  };

  if (!isCoinsLoaded) {
    return <TextLoader height="20px" className={cx("rounded-lg mr-2", className)} />
  }

  return (
    <>
      <Button 
        onClick={handleShowDrawer}
        className={className}
        fw="normal"
        colorSchema="secondary" 
        justify="between"
      >
        <span className="inline-flex items-center">
          <CoinIcon coin={coin} className='w-3 mr-1.5'/>
          {coin}
        </span>
        <IconSortUnfold/>
      </Button>

      <Drawer
        open={isOpen}
        onInteractOutside={handleHideDrawer}
      >
        <SwipeHead
          title={intl.formatMessage({ id: 'header.p.pool'})}
          action={<FormattedMessage id="header.cancel"/>}
          onDismiss={handleHideDrawer}
        />
        <List.Root className='mb-2'>
          {coins.map((coinItem: string) => {
            return (
              <List.Item 
                onClick={() => handleChangeCoin(coinItem)}
                key={coinItem} 
                mb="xs" 
                theme='secondary' 
                className='' 
                tag="button"
              >
                {coinItem}
                <CoinIcon coin={coinItem} className='w-4 h-4 ml-1.5'/>
              </List.Item>
            )
          })}
        </List.Root>
      </Drawer>
    </>
  )
};

export default SelectCoinMobile;
