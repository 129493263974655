import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import cx from 'classnames';
import _get from 'lodash/get';
import _find from 'lodash/find';
import * as List from '@/shared/ui/List';
import { LANGUAGES_LIST, LANGUAGES_NAME } from '@/shared/constants/language';
import { useNavigation } from '@/processes/Router';
import { IconGlobe, IconCheckmark, IconArrowRight } from '@/shared/ui/Icons';

/* -------------------------------------------------------------------------------------------------
 * Trigger
 * -----------------------------------------------------------------------------------------------*/

type LanguageSwitcherMobileTriggerProps = {
  onClick?: () => void;
}

const LanguageSwitcherTriggerMobile = (props: LanguageSwitcherMobileTriggerProps) => {
  const { locale } = useNavigation();
  const {
    onClick,
  } = props;

  return (
    <>
      <List.Root>
        <List.Item 
          onClick={onClick}
          mb="xs" 
          theme='secondary' 
          className='' 
          tag='button'
        >
          <span className='inline-flex items-center'>
            <IconGlobe className="w-4 mr-2"/>
            <FormattedMessage id="header.language"/>: {' '}
            {LANGUAGES_NAME[locale]}
          </span>
          <IconArrowRight className="opacity-30 ml-2"/>
        </List.Item>
      </List.Root>
    </>
  );
};

/* -------------------------------------------------------------------------------------------------
 * List
 * -----------------------------------------------------------------------------------------------*/

type LanguageSwitcherMobileListProps = {
  onSwitch: (lang: string) => void;
}

const LanguageSwitcherListMobile = (props: LanguageSwitcherMobileListProps) => {
  const { locale, switchLocale } = useNavigation();

  const {
    onSwitch,
  } = props;

  const handleLanguageChange = (lang: string) => {
    switchLocale(lang);
    onSwitch(lang);
  };

  return (
    <>
      <List.Root className='backdrop-blur-[40px]'>
        {LANGUAGES_LIST.map((lang) => {
          return (
            <List.Item 
              onClick={() => handleLanguageChange(lang)}
              tag="button" 
              theme='secondary' 
              className='text-[15px]' 
              key={lang}
            >
              {LANGUAGES_NAME[lang]}
              {lang === locale ? <IconCheckmark className="text-[#FF5F01] w-4"/> : null}
            </List.Item>
          )
        })}
      </List.Root>
    </>
  );
};

const Trigger = LanguageSwitcherTriggerMobile;
const LocalesList = LanguageSwitcherListMobile;

export {
  Trigger,
  LocalesList,
  LanguageSwitcherListMobile,
  LanguageSwitcherTriggerMobile
};
