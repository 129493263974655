import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl'
import cx from 'classnames';
import * as List from '@/shared/ui/List';
import * as SwipeList from '@/shared/ui/SwipeList';
import { IconProduct, IconArrowRight, IconArrowLeft } from '@/shared/ui/Icons';
import Drawer from '@/shared/ui/Drawer';
import * as LanguageSwitcherMobile from '~/Header/components/LanguageSwitcher/LanguageSwitcherMobile';
import { NAVIGATION_PRODUCTS, NAVIGATION_PRODUCTS_LOGGED_IN, NAVIGATION_MORE } from '~/Header/constants/navigation';
import s from './ProductsMobile.module.scss';
import { useMinerAccount } from "@/processes/MinerAccounts";

type ProductsMobileProps = {
  className?: string;
}

const ProductsMobile = (props: ProductsMobileProps) => {
  const { 
    className = '',
  } = props;
  const intl = useIntl();
  const [isOpen, setIsOpen] = useState(false);
  const [swipeState, setSwipeState] = useState('menu');
  const { isAuth } = useMinerAccount();

  const handleShowDrawer = () => { setIsOpen(true); }
  const handleHideDrawer = () => { setIsOpen(false); }

  return (
    <>  
      <button
        aria-label={intl.formatMessage({ id: 'header.p' })}
        className={cx(s.toggler, className)}
        onClick={handleShowDrawer}
        aria-expanded={isOpen}
        aria-controls="products-menu"
      >
        <IconProduct className="w-5"/>
      </button>

      <Drawer
        open={isOpen}
        onInteractOutside={handleHideDrawer}
      >
        <SwipeList.Root 
          defaultValue={swipeState} 
          firstScreen='menu'
          className='max-h-[320px] h-[75vh]'
        >
          <SwipeList.Page
            className='max-h-[55vh]'
            value="menu"
            title={intl.formatMessage({ id: 'header.menu'})}
            action={<FormattedMessage id="header.cancel"/>}
            onDismiss={handleHideDrawer}
          >
            <List.Root className={cx('mb-2')}>
              {(isAuth ? NAVIGATION_PRODUCTS_LOGGED_IN : NAVIGATION_PRODUCTS).map(({ title, link }, idx) => {
                return (
                  <List.Item key={`${link}-${idx}`} mb="xs" theme='secondary' className='' target="_blank" href={link}>
                    <FormattedMessage id={title}/>
                  </List.Item>
                )
              })}
            </List.Root>

            <List.Root className={cx('mb-2')}>
              <List.Item 
                onClick={() => setSwipeState('more')}
                mb="xs" 
                theme='secondary' 
                className='' 
                tag='button'
              >
                <FormattedMessage id="header.more"/>
                <IconArrowRight className="opacity-30 ml-2"/>
              </List.Item>
            </List.Root>

            <LanguageSwitcherMobile.Trigger onClick={() => setSwipeState('langs')}/>
          </SwipeList.Page>

          <SwipeList.Page
            className='max-h-[58vh]'
            value="more"
            title={intl.formatMessage({ id: 'header.more'})}
            action={<>
              <IconArrowLeft className="w-4 mr-2"/>
              <FormattedMessage id="header.menu"/>              
            </>}
            onDismiss={() => setSwipeState('menu')}
          >
            <List.Root>
              {NAVIGATION_MORE.map(({ title, link }, idx) => {
                return (
                  <List.Item key={`${link}-${idx}`} mb="xs" theme='secondary' className='' target="_blank" href={link}>
                    <FormattedMessage id={title}/>
                  </List.Item>
                )
              })}
            </List.Root>
          </SwipeList.Page>

          <SwipeList.Page
            value="langs"
            title={intl.formatMessage({ id: 'header.language'})}
            action={<>
              <IconArrowLeft className="w-4 mr-2"/>
              <FormattedMessage id="header.menu"/>              
            </>}
            onDismiss={() => setSwipeState('menu')}
          >
            <LanguageSwitcherMobile.LocalesList onSwitch={() => {
              handleHideDrawer();
              // switch state after delay, to avoid animation effect
              setTimeout(() => setSwipeState('menu'), 500);
            }}/>
          </SwipeList.Page>
        </SwipeList.Root>
      </Drawer>
    </>  
  );
}

export default ProductsMobile;
