import _get from 'lodash/get'
import { IconBtc, IconEtc, IconRvn, IconKaspa, IconDynex, IconGram } from '@/shared/ui/Icons'

const COINS = { 
  ETC: IconEtc,
  RVN: IconRvn,
  KAS: IconKaspa,
  BTC: IconBtc,
  DNX: IconDynex,
  GRAM: IconGram,
};

type CoinIconProps = {
  coin: keyof typeof COINS | string;
  className?: string;
}

const CoinIcon = (props: CoinIconProps) => {
  const {
    coin,
    className = 'w-5',
  } = props;

  const Icon = _get(COINS, coin);

  if (!Icon) { return null; }

  return (
    <Icon className={className}/>
  );
}

export default CoinIcon;
