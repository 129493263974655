import React, { useId, forwardRef } from 'react';
import cx from 'classnames';
import s from './Input.module.scss';
import FieldWrapper from '../FieldWrapper';

type InputProps = {
  label?: string;
  labelInfo?: string;
  labelPostfix?: string;
  className?: string;
  inputClassName?: string;
  type?: string,
  placeholder?: string,
  id?: string,
  hint?: string;
  error?: any;
  suffix?: any;
  prefix?: any;
  full?: boolean;
  isDisabled?: boolean;
  [x:string]: any;
}

const Input = forwardRef((props: InputProps, ref) => {
  const {
    label,
    labelInfo = '',
    labelPostfix = '',
    labelTextClassName,
    type = 'text',
    id = '',
    className = '',
    inputClassName = '',
    hint = '',
    error = '',
    suffix,
    prefix,
    full = false,
    isDisabled = false,
    ...restProps
  } = props;

  const inputId = useId();

  return (
    <FieldWrapper
      label={label}
      labelInfo={labelInfo}
      labelPostfix={labelPostfix}
      labelTextClassName={labelTextClassName}
      inputId={inputId}
      className={className}
      hint={hint}
      error={error}
      full={full}
    >
      <div
        className={cx(s.inputWrap, {
          [s.hasError]: !!error,
          [s.hasSuffix]: !!suffix,
          [s.hasPrefix]: !!prefix,
          [s.full]: full,
        })}
      >
        {prefix ? (
          <label htmlFor={inputId} className={s.prefix}>
            {prefix}
          </label>
        ) : null}
        <input
          type={type}
          className={cx(s.input, inputClassName)}
          id={inputId}
          disabled={isDisabled}
          // @ts-ignore
          ref={ref}
          {...restProps}
        />
        {suffix ? (
          <label htmlFor={inputId} className={s.suffix}>
            {suffix}
          </label>
        ) : null}
      </div>
    </FieldWrapper>
  );
});

Input.displayName = 'Input';

export default Input;
