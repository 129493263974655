import React, { useId, forwardRef } from 'react';
import cx from 'classnames';
import s from './RadioGroup.module.scss';
import FieldWrapper from '../FieldWrapper';

type RadioGroupProps = {
  items: any[];
  label?: string;
  name?: string;
  labelInfo?: string;
  labelPostfix?: string;
  className?: string;
  inputClassName?: string;
  labelClassName?: string;
  radioGroupClassName?: string;
  type?: string,
  placeholder?: string,
  id?: string,
  hint?: string;
  error?: any;
  suffix?: any;
  prefix?: any;
  full?: boolean;
  isDisabled?: boolean;
  defaultValue?: string;
  [x:string]: any;
}

const RadioGroup = forwardRef((props: RadioGroupProps, ref) => {
  const {
    label,
    labelInfo = '',
    labelPostfix = '',
    className = '',
    labelClassName = '',
    radioGroupClassName = '',
    hint = '',
    error = '',
    full = false,
    items = [],
    isDisabled,
    defaultValue,
    ...restProps
  } = props;

  return (
    <FieldWrapper 
      label={label}
      labelInfo={labelInfo}
      labelPostfix={labelPostfix}
      className={className}
      hint={hint}
      error={error}
      full={full}
    >
      <div className={cx(s.radioGroup, radioGroupClassName)}>
        {items.map(({ value, label }) => {
          const checkedProps = defaultValue ? {
            checked: defaultValue === value
          }: {};
          return (
            <label className={s.radioButton} key={value}>
              <input
                type="radio"
                value={value}
                disabled={isDisabled}
                className={s.input}
                // @ts-ignore
                ref={ref}
                {...checkedProps}
                {...restProps}
              />
              <span className={cx(s.label, labelClassName)}>
                {label}
              </span>
            </label>
          )
        })}
      </div>
    </FieldWrapper>
  );
});

RadioGroup.displayName = 'RadioGroup';

export default RadioGroup;
