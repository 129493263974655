import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import s from './TimeSwitcher.module.scss';
import RadioGroup from "@/shared/ui/RadioGroup";
import { useSelector, useDispatch } from 'react-redux';
import { 
  DAY, WEEK, MONTH,
  setDateRange, 
  selectDateRange,
} from "@/processes/Settings";

const TimeSwitcher = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const dateRange = useSelector(selectDateRange)

  const handleChange = (e: any) => {
    const dateRangeNewValue = e.target.value || DAY;
    dispatch(setDateRange(dateRangeNewValue));
  };

  return (
    <RadioGroup
      full={true}
      defaultValue={dateRange}
      onChange={handleChange}
      name="dateRange"
      items={[
        { value: DAY, label: intl.formatMessage({id: 'time.switcher.24h'})},
        { value: WEEK, label: intl.formatMessage({id: 'time.switcher.7d'})},
        { value: MONTH, label: intl.formatMessage({id: 'time.switcher.1m'})},
      ]}
      labelClassName={s.label}
    />
  );
}

export default TimeSwitcher;
